@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  z-index: 100;
}

.wrapper,
.navwrapper {
  @include grid--xs-v2;
}

.navigation {
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  padding: getSpacing(3) 0;
}

.items,
.divider,
.desktopLanguages {
  display: none;
}

.cta {
  margin-left: auto;
}

.burgerbuttons {
  margin-left: getSpacing(1);

  .close {
    display: none;
  }
}

.mobileitems {
  grid-column: 1/-1;
  display: none;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 98px);
  gap: getSpacing(2);
}

.mobileitemwrapper {
  position: relative;
  text-align: center;
  padding-bottom: getSpacing(2);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: getSpacing(2);
    height: 1px;
    background: var(--color-background-primary-darkest);
    transform: translateX(-50%);
  }
}

.mobilelanguages {
  margin: 0 auto getSpacing(4);
}

.isOpen {
  height: 100vh;
  overflow: hidden;
  background: var(--color-background-primary-lightest);

  .burger {
    display: none;
  }

  .close {
    display: inherit;
  }

  .mobileitems {
    display: flex;
  }
}

.isLight {
  .burgerbuttons {
    svg path {
      fill: var(--color-background-primary-lightest);
    }
  }

  .item {
    color: var(--color-background-primary-white);
  }

  .itemdivider {
    background-color: var(--color-background-primary-white);
  }

  .divider {
    background-color: var(--color-background-primary-white);
  }

  .navwrapper {
    background-color: var(--color-border-primary-darkest);
  }

  .wrapper {
    background-color: var(--color-background-primary-lightest);
  }
  // &.section {
  //   background-color: var(--color-border-primary-darkest);
  // }
  // .mobileitems {
  //   color: var(--color-background-primary-white);
  // }
  // .mobileitemwrapper {
  //   &::after {
  //     background: var(--color-background-primary-white);
  //   }
  // }
  // .mobilelanguages{
  //   color: var(--color-background-primary-white)!important;
  //   svg path{
  //     fill: var(--color-background-primary-white);
  //   }
  // }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .wrapper,
  .navwrapper {
    @include grid--md-v2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    padding: 0 getSpacing(3);
    z-index: getZIndex('navigation');
    background-color: transparent;
  }

  .wrapper,
  .navwrapper {
    @include grid--lg-v2;
    background: transparent !important;
  }

  .wrapper {
    display: none;
  }

  .items {
    margin-left: auto;
    display: flex;
  }

  .item {
    @include text-misc-button();
    color: var(--color-navy-900);
    padding: 0 getSpacing(2);
  }

  .cta {
    margin-left: getSpacing(1);
  }

  .itemdivider {
    display: block;
    margin: 0 getSpacing(1);
    padding: 0;
    width: 1px;
    height: getSpacing(2);
    background-color: var(--color-border-primary-darkest);
    border: none;
  }

  .divider {
    display: block;
    margin: 0 getSpacing(3);
    padding: 0;
    width: 1px;
    height: getSpacing(2);
    background-color: var(--color-border-primary-darkest);
    border: none;
  }

  .desktopLanguages {
    display: block;
  }

  .burgerbuttons {
    display: none;
  }
}
